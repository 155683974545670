import React from 'react';
import '../../App.css';
import AboutMe from '../AboutMe';
import Card from '../Card';
import Footer from '../Footer';
import Information from '../Information';
import History from '../History';

function Home() {
  return (
    <>
      <AboutMe />
      <Information />
      <Card />
      <History />
      <Footer />
    </>
  );
}
export default Home;
