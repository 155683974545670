import React from 'react';

import resume from '../images/JustinMartinResume.pdf';
import './History.css';

function History() {
  return (
    <div className='history' id='history'>
      <video
        src='video/video-1.mp4'
        autoPlay
        loop
        muted
        className='history-video'
      />
      <div className='history-resume-section'>
        <h1> My Resume </h1>
        <img
          src='./images/resume.png'
          alt='resume'
          className='history-resumeIMG'
        />
        <a href={resume} download>
          <button type='download'>~Download~</button>
        </a>
      </div>
    </div>
  );
}

export default History;
