import React from 'react';
import './Footer.css';
import emailjs from 'emailjs-com';

export default function Footer() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm('service_nr3xazo', 'Gmail', e.target, 'Justin Martin')
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div className='footer-container'>
      <section className='footer-contact'>
        <p className='footer-header'>Feel free to contact me</p>
        <div className='email-input-section'>
          <form onSubmit={sendEmail}>
            <div className='input-container'>
              <input
                className='footer-input'
                name='name'
                type='text'
                placeholder='Your Name'
              />
              <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Email'
              />
              <input
                className='footer-input'
                name='subject'
                type='text'
                placeholder='Subject'
              />
              <input
                className='footer-input-text'
                name='message'
                type='text'
                placeholder='Write Message Here'
              />
            </div>
            <button className='footer-button' type='submit'>
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}
