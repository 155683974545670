import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Card() {
  return (
    <div className='cards' id='projects'>
      <h1> check out these projects</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <CardItem
            src='images/chatable.png'
            text='HTML, CSS, React, Node.js, Socket.io, MUI, '
            label='Chatable'
            link='https://adoring-wright-0eba98.netlify.app/'
          />
          <ul className='cards__items'>
            <CardItem
              src='images/cardcoven.png'
              text='HTML, CSS, JavaScript, React, Node.js'
              label='Card Coven'
              link='https://card-coven2020.netlify.app/'
            />
            <CardItem
              src='images/adventure.png'
              text='HTML CSS JavaScript'
              label='The Adventurers Guild'
              link='https://ryanleviathan.github.io/adventurers-guild/'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Card;
