import React from 'react';
import '../App.css';
import './AboutMe.css';
import twitter from '../images/twitter.png';
import LinkedIn from '../images/in.png';
import gitHub from '../images/gitHub.png';

function AboutMe() {
  return (
    <div className='about-me-container' id='home'>
      <video src='video/video-3.mp4' autoPlay loop muted />
      <h1>Justin Martin</h1>
      <p>
        I am a Full Stack Software Engineer in the beautiful Pacific NorthWest
      </p>

      <div className='social-links'>
        <div className='social-link-link'>
          <a href='https://twitter.com/JustinMartin7x'>
            <img src={twitter} alt='twit' className='social-link-linked' />
          </a>
        </div>

        <div className='social-link-link'>
          <a href='https://www.linkedin.com/in/justin-martin7x/'>
            <img
              src={LinkedIn}
              alt='Linked-in'
              className='social-link-linked'
            />
          </a>
        </div>

        <div className='social-link-link'>
          <a href='https://github.com/JustinMartin7x'>
            <img src={gitHub} alt='Github' className='social-link-git' />
          </a>
        </div>
      </div>
      <div className='about-me-btns'></div>
    </div>
  );
}

export default AboutMe;
