import React from 'react';
import './Information.css';

export default function Information() {
  return (
    <div className='information' id='information'>
      <div className='information-container'>
        <div className='information-top-container'>
          <div className='information-title'>
            {' '}
            <h1 className='information-title'>About me</h1>{' '}
          </div>
        </div>
        <div className='information-photo-container'>
          <img
            src='/images/selfImage.jpg'
            alt='me'
            className='information-image'
          />
        </div>
        <div className='information-text-area'>
          <div className='information-paragraph'>
            <p className='information-text'>
              Detail orientated Software Developer. My Military experience has
              given me a set of motivational based principles to embrace the
              toughest challenges. I have traveled to three continents13
              countries and 49 states. I am fulfilling my dream of becoming a
              Software Developer.
            </p>
          </div>
          <div className='information-skills'>
            <div>
              <ul>
                <h3>Languages:</h3>
                <li> Javascript</li>
                <li> HTML</li>
                <li>CSS</li>
                <li>SQL</li>
              </ul>
            </div>
            <div>
              <ul>
                <h3>Front-End:</h3>
                <li>React</li>
                <li>Redux</li>
                <li>MUI</li>
              </ul>
            </div>
            <div>
              <ul>
                <h3>Back-End:</h3>
                <li>NodeJs</li>
                <li>Express</li>
                <li>Socket.io</li>
              </ul>
            </div>
            <div>
              <ul>
                <h3>Testing:</h3>
                <li>Jest</li>
                <li>QUnit</li>
              </ul>
            </div>
            <div>
              <ul>
                <h3>Databases:</h3>
                <li>Postgres SQL</li>
                <li>Heroku</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
